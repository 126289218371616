export const parseAddress = address => {
  if (address) {
    return [
      `${address.address}, ${address.number}, ${address.complement}`,
      ` ${address.neighborhood} - ${address.city}/${address.state.uf}, ${address.zipCode}`
    ];
  }
  return ["Não informado"];
};

export const parseAdressSingleLine = address =>
  `${address.address}, ${address.neighborhood} - ${address.city}/${address.state.uf}`;

export const parseAdressMinimal = address => {
  if (address) {
    return `${address.city}/${address.state.uf}`;
  }
  return "Não informado";
};
