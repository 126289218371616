<template>
  <v-card class="mt-3 pt-7">
    <validation-observer>
      <v-form @submit.prevent="submit()" slot-scope="{ invalid }">
        <div class="px-5">
          <h3>Tipo de solicitação</h3>
          <v-row class="mt-n8">
            <v-col cols="12" sm="6">
              <radio-field
                v-model="formNewRequest.type.id"
                type="genericText"
                label="Tipo"
                name="requestType"
                :isRequired="true"
                :items="items"
              />
            </v-col>
          </v-row>

          <div v-if="labelSeletectPerson">
            <h3>Selecionar {{ labelSeletectPerson }}</h3>

            <div @click="seletectPerson()" class="add-new-person mt-4 mb-6">
              <span v-if="!labelSeletectedPerson()" class="font-weight-medium"
                >Selecione um {{ labelSeletectPerson }}</span
              >
              <span v-if="labelSeletectedPerson()" class="font-weight-medium">
                {{ labelSeletectedPerson() }}
                <v-icon
                  class="font-weight-medium black-1--text ml-4 mt-n1"
                  size="22"
                  >mdi-close</v-icon
                >
              </span>
            </div>
          </div>

          <h3>Informações</h3>
          <v-row>
            <v-col cols="12">
              <Field
                v-model="formNewRequest.description"
                type="textarea"
                label="Descrição"
                name="description"
                max="250"
                :isRequired="true"
              />
            </v-col>
          </v-row>
        </div>

        <v-divider></v-divider>

        <v-card-actions class="d-flex flex-wrap justify-space-between">
          <v-btn
            @click="$router.back()"
            color="#FFF"
            :block="$isMobile"
            class="mt-1 cancel-button black-3--text"
            >Cancelar</v-btn
          >
          <v-btn
            type="submit"
            color="secondary-color-1"
            :block="$isMobile"
            :disabled="invalid"
            class="ma-0 mt-1 next-step-button white--text"
            >Criar</v-btn
          >
        </v-card-actions>
      </v-form>
    </validation-observer>

    <!-- PEV -->
    <select-partner-dialog
      :backBtn="() => (PEVtype.dialog = false)"
      :state="PEVtype.dialog && !PEVtype.partnerSelected"
      v-model="PEVtype.partnerSelected"
    />
    <select-pev-dialog
      :partnerSelected="PEVtype.partnerSelected"
      :backBtn="() => (PEVtype.partnerSelected = '')"
      :state="
        PEVtype.dialog && !!PEVtype.partnerSelected && !PEVtype.pevSelected
      "
      v-model="PEVtype"
    />

    <!-- Donation -->
    <select-donator-dialog
      :backBtn="() => (donationType.showContent = false)"
      :state="donationType.showContent"
      v-model="donationType"
    />
  </v-card>
</template>
<script>
  import Field from "@/modules/core/views/components/Field";
  import RadioField from "@/modules/core/views/components/RadioField";
  import { requestTypes } from "@/modules/agenda/enums/requestTypes.enum";
  import SelectPevDialog from "@/modules/agenda/views/components/SelectPevDialog";
  import SelectPartnerDialog from "@/modules/agenda/views/components/SelectPartnerDialog";
  import SelectDonatorDialog from "@/modules/agenda/views/components/SelectDonatorDialog";

  import { ValidationObserver } from "vee-validate";
  import { isEmpty } from "lodash";

  export default {
    name: "NewRequestForm",

    props: {
      donator: {
        type: Object
      },
      formNewRequest: {
        type: Object,
        required: true
      }
    },
    components: {
      Field,
      RadioField,
      ValidationObserver,
      SelectPartnerDialog,
      SelectDonatorDialog,
      SelectPevDialog
    },
    data: () => ({
      PEVtype: {
        partnerSelected: "",
        pevSelected: "",
        pevSelectedLabel: "",
        dialog: false
      },
      donationType: {
        showContent: false,
        donatorSelected: "",
        donatorSelectedLabel: ""
      },
      items: []
    }),
    mounted() {
      this.items = Object.values(requestTypes);
    },
    watch: {
      labelSeletectPerson() {
        this.PEVtype = {
          partnerSelected: "",
          pevSelected: "",
          pevSelectedLabel: "",
          dialog: false
        };
        this.donationType = {
          showContent: false,
          donatorSelected: "",
          donatorSelectedLabel: ""
        };
        this.$emit("clearDonator");
      }
    },
    computed: {
      labelSeletectPerson() {
        if (this.formNewRequest.type.id === 1) {
          return "Parceiro";
        }
        if (
          this.formNewRequest.type.id !== 1 &&
          typeof this.formNewRequest.type.id === "number"
        ) {
          return "Doador";
        }
        return "";
      }
    },
    methods: {
      submit() {
        if (this.formNewRequest.type.id === 1) {
          delete this.formNewRequest.donator;
          this.formNewRequest.voluntaryDeliveryPoint.id = this.PEVtype.pevSelected;
        } else {
          delete this.formNewRequest.voluntaryDeliveryPoint;
          if (this.donationType.donatorSelected) {
            this.formNewRequest.donator.id = this.donationType.donatorSelected;
          }
        }
        this.$emit("submit", this.formNewRequest);
      },
      labelSeletectedPerson() {
        if (this.donationType.donatorSelected) {
          return this.donationType.donatorSelectedLabel;
        }
        if (!isEmpty(this.donator)) {
          return this.getDonatorName();
        }
        if (this.PEVtype.pevSelectedLabel) {
          return this.PEVtype.pevSelectedLabel;
        }
        return "";
      },
      getDonatorName() {
        return `${
          this.donator.donatorPF
            ? this.donator.donatorPF.name
            : this.donator.donatorPJ.fantasyName
        }`;
      },
      seletectPerson() {
        if (this.formNewRequest.type.id === 1) {
          this.PEVtype = {
            partnerSelected: "",
            pevSelected: "",
            pevSelectedLabel: "",
            dialog: true
          };
        } else {
          this.donationType = {
            showContent: true,
            donatorSelected: "",
            donatorSelectedLabel: ""
          };
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "~@/assets/stylesheets/styleguide";

  .add-new-person {
    background-color: #f3f6f9;
    margin-top: 2em;
    padding: 1.2em;
    border: 1px dashed $primary-color-1;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 100%;
  }

  .label-add-donator {
    cursor: pointer;
  }
</style>
