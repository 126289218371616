<template>
  <div
    class="data-not-found fill d-flex flex-column justify-center align-center"
  >
    <img class="mb-2" :src="imgSrc" alt="" />

    <p v-if="isString" class="mb-2 fill">{{ title }}</p>

    <template v-if="isArray">
      <p class="fill ma-0" v-for="(line, index) in title" :key="index">
        {{ line }}
      </p>
    </template>

    <v-btn
      v-if="canSeeAction && btnAction && btnLabel"
      @click="btnAction"
      color="secondary-color-1"
      class="action-button mt-3"
      >{{ btnLabel }}</v-btn
    >
  </div>
</template>
<script>
  export default {
    name: "DataNotFound",

    props: {
      title: {
        type: null,
        required: true,
        validator: title => typeof title === "string" || Array.isArray(title)
      },
      btnLabel: {
        type: String,
        required: false
      },
      btnAction: {
        type: Function,
        required: false
      },
      imgSrc: {
        type: String,
        required: true
      },
      canSeeAction: {
        type: Boolean,
        required: false,
        default: () => false
      }
    },

    computed: {
      isArray() {
        return Array.isArray(this.title);
      },
      isString() {
        return typeof this.title === "string";
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .data-not-found {
    padding-bottom: 50px;

    img {
      width: 140px;
    }

    p {
      margin-top: 25px;
      margin-bottom: -10px;
      font-size: 18px;
      font-weight: 500;
      color: $black-1;
      text-align: center;
    }

    .action-button {
      height: 56px !important;
      width: 262px !important;
      border-radius: 5px !important;
      color: $white-1;
      display: block;
    }
  }
</style>
