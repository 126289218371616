<template>
  <v-dialog v-model="state" :persistent="true" width="536">
    <v-card class="modal">
      <v-card-title class="modal-title">Selecionar Parceiro</v-card-title>

      <div class="px-5">
        <v-text-field
          solo
          flat
          full-width
          background-color="white-1"
          color="secondary-color-1"
          placeholder="Buscar (Ex: CPF, CNPJ ou nome)"
          prepend-inner-icon="mdi-magnify"
          @input="debounceInput"
        ></v-text-field>
      </div>
      <v-divider></v-divider>

      <validation-observer>
        <v-form @submit.prevent="submit" slot-scope="{ invalid }">
          <div v-if="partners.length" class="data-container px-5">
            <v-row class="mt-n4">
              <v-col cols="12" sm="6">
                <radio-field
                  v-model="partnerSelected"
                  type="genericText"
                  label="Parceiros"
                  name="requestType"
                  :isRequired="true"
                  :items="partners"
                />
              </v-col>
            </v-row>

            <infinite-loading spinner="waveDots" @infinite="loadMore">
              <div slot="no-more"></div>
              <div slot="no-results"></div>
            </infinite-loading>
          </div>

          <div v-if="!partners.length" class="mt-5">
            <data-not-found
              :title="dataNotFound"
              :imgSrc="require('@/assets/partner.png')"
              :btnAction="dataNotFound.btnAction"
            />
          </div>

          <v-divider></v-divider>

          <v-card-actions class="d-flex flex-wrap justify-space-between">
            <v-btn
              class="mt-1 black-3--text cancel-button"
              color="#FFF"
              @click="backBtn"
              :block="$isMobile"
              >Voltar</v-btn
            >
            <v-btn
              type="submit"
              :disabled="invalid"
              color="secondary-color-1"
              class="ma-0 mt-1 white--text next-step-button"
              :block="$isMobile"
              >Selecionar parceiro</v-btn
            >
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapActions } from "vuex";
  import { debounce } from "lodash";
  import { ValidationObserver } from "vee-validate";
  import InfiniteLoading from "vue-infinite-loading";

  import RadioField from "@/modules/core/views/components/RadioField";
  import DataNotFound from "@/modules/core/views/components/DataNotFound";

  export default {
    name: "SelectPartnerDialog",

    props: {
      value: {
        type: null
      },
      backBtn: {
        type: Function,
        required: true
      },
      state: {
        type: Boolean,
        required: true
      }
    },

    components: {
      ValidationObserver,
      InfiniteLoading,
      DataNotFound,
      RadioField
    },

    data: () => ({
      partnerSelected: "",
      partners: [],
      searchTerm: "",
      filterPristine: true,
      page: 1,
      limit: 10
    }),

    async mounted() {
      await this.loadMore();
    },

    computed: {
      dataNotFound() {
        return this.filterPristine
          ? "Busque por um parceiro"
          : "Parceiro não encontrado";
      }
    },

    methods: {
      ...mapActions("partner", ["getPevs"]),
      ...mapActions(["toggleSnackbar"]),

      async fetchPartners() {
        try {
          const payload = this.mountPayload();
          const response = await this.getPevs(payload);
          return this.formatData(response.data);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      },

      formatData(data) {
        return data.map(partner => ({
          id: partner.id,
          description: partner.companyName
        }));
      },

      async loadMore($state) {
        const data = await this.fetchPartners();
        this.page = this.page + 1;

        if (data.length) {
          this.partners = [...this.partners, ...data];
          this.partners = this.partners
            .map(e => JSON.stringify(e))
            .reduce((acc, cur) => (acc.includes(cur) || acc.push(cur), acc), [])
            .map(e => JSON.parse(e));
          if ($state) {
            $state.loaded();
          }
        } else {
          if ($state) {
            $state.complete();
          }
        }
      },

      debounceInput: debounce(async function(value) {
        this.filterPristine = false;
        this.searchTerm = value;
        this.page = 1;

        this.partners = await this.fetchPartners();
      }, 800),

      submit() {
        this.$emit("input", this.partnerSelected);
      },

      mountPayload() {
        return {
          companyName: this.searchTerm,
          page: this.page,
          limit: this.limit
        };
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .data-container {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (max-width: 460px) {
      height: 260px;
    }
  }
</style>
