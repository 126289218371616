<template>
  <v-dialog v-model="state" :persistent="true" width="536">
    <v-card class="modal">
      <v-card-title class="modal-title">Selecionar PEV</v-card-title>

      <v-divider></v-divider>

      <validation-observer>
        <v-form @submit.prevent="submit" slot-scope="{ invalid }">
          <div v-if="pevs.length" class="data-container px-5">
            <v-row class="mt-n4">
              <v-col cols="12" sm="6">
                <radio-field
                  v-model="PEVtype.pevSelected"
                  type="genericText"
                  label="pevs"
                  name="requestType"
                  :isRequired="true"
                  :items="pevs"
                />
              </v-col>
            </v-row>

            <infinite-loading spinner="waveDots" @infinite="loadMore">
              <div slot="no-more"></div>
              <div slot="no-results"></div>
            </infinite-loading>
          </div>

          <div v-if="!pevs.length" class="mt-5">
            <data-not-found
              :title="dataNotFound"
              :imgSrc="require('@/assets/partner.png')"
              :btnAction="dataNotFound.btnAction"
            />
          </div>

          <v-divider></v-divider>

          <v-card-actions class="d-flex flex-wrap justify-space-between">
            <v-btn
              class="mt-1 black-3--text cancel-button"
              color="#FFF"
              @click="backBtn"
              :block="$isMobile"
              >Voltar</v-btn
            >
            <v-btn
              type="submit"
              :disabled="invalid"
              color="secondary-color-1"
              class="ma-0 mt-1 white--text next-step-button"
              :block="$isMobile"
              >Selecionar PEV</v-btn
            >
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapActions } from "vuex";
  import { debounce } from "lodash";
  import { ValidationObserver } from "vee-validate";
  import InfiniteLoading from "vue-infinite-loading";

  import RadioField from "@/modules/core/views/components/RadioField";
  import DataNotFound from "@/modules/core/views/components/DataNotFound";
  import { parseAdressMinimal } from "@/modules/core/helpers/addressHelpers";

  export default {
    name: "SelectPevDialog",

    props: {
      value: {
        type: null
      },
      backBtn: {
        type: Function,
        required: true
      },
      partnerSelected: {
        required: true
      },
      state: {
        type: Boolean,
        required: true
      }
    },

    components: {
      ValidationObserver,
      InfiniteLoading,
      DataNotFound,
      RadioField
    },

    data: () => ({
      PEVtype: {
        partnerSelected: "",
        pevSelected: "",
        pevSelectedLabel: "",
        dialog: true
      },
      pevs: [],
      partner: "",
      searchTerm: "",
      filterPristine: true,
      page: 1,
      limit: 10
    }),

    async mounted() {
      await this.loadMore();
    },

    computed: {
      dataNotFound() {
        return this.filterPristine
          ? "Busque por uma PEV"
          : "PEV não encontrado";
      }
    },

    watch: {
      partnerSelected() {
        this.loadMore();
      }
    },

    methods: {
      ...mapActions("partner", ["getPevs", "getPartnerById"]),
      ...mapActions(["toggleSnackbar"]),

      async fetchPEVs() {
        if (this.partnerSelected) {
          try {
            const { data } = await this.getPartnerById(this.partnerSelected);
            return this.formatData(data);
          } catch (error) {
            this.toggleSnackbar({
              text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
              type: "error"
            });
          }
        }
      },

      formatData(data) {
        this.partner = data;
        return data.voluntaryDeliveryPoints.map(pev => ({
          id: pev.id,
          description: `${pev.name}, ${parseAdressMinimal(pev.address)}`
        }));
      },

      async loadMore($state) {
        const data = await this.fetchPEVs();

        if (data) {
          this.pevs = [...data];
          if ($state) {
            $state.complete();
          }
        }
      },

      debounceInput: debounce(async function(value) {
        this.filterPristine = false;
        this.searchTerm = value;

        this.pevs = await this.fetchPEVs();
      }, 800),

      submit() {
        this.PEVtype.pevSelectedLabel = this.getPevSelectedLabel();
        this.PEVtype.dialog = false;
        this.$emit("input", this.PEVtype);
      },

      getPevSelectedLabel() {
        return `${this.partner.companyName} - ${
          this.partner.voluntaryDeliveryPoints.find(
            el => el.id === this.PEVtype.pevSelected
          ).name
        }`;
      },

      mountPayload() {
        return {
          description: this.searchTerm,
          page: this.page,
          limit: this.limit
        };
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .data-container {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (max-width: 460px) {
      height: 300px;
    }
  }
</style>
