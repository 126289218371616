export const requestTypes = {
  PEV: {
    description: "Pev",
    id: 1
  },
  DONATION: {
    description: "Doação",
    id: 2
  },
  CAMPAIGN: {
    description: "Campanha",
    id: 3
  },
  UNDO: {
    description: "Desfazimento",
    id: 4
  },
  GYMKHANA: {
    description: "Gincana",
    id: 5
  }
};
