<template>
  <v-container class="content-page-half">
    <div class="d-flex justify-space-between align-center">
      <h2 class="white--text">
        <v-icon @click="$router.back()" class="white--text"
          >mdi-arrow-left</v-icon
        >Nova Solicitação
      </h2>
    </div>

    <NewRequestForm
      :formNewRequest="formNewRequest"
      :donator="donator"
      @submit="submitHandler"
      @clearDonator="donator = {}"
    />
  </v-container>
</template>

<script>
  import NewRequestForm from "@/modules/agenda/views/components/NewRequestForm";

  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "newRequestForm",

    data: () => ({
      donator: {},
      formNewRequest: {
        description: "",
        donator: {
          id: ""
        },
        voluntaryDeliveryPoint: {
          id: ""
        },
        type: {
          id: ""
        },
        crc: {
          id: ""
        }
      }
    }),

    components: {
      NewRequestForm
    },

    computed: {
      ...mapGetters("user", {
        selectedCrc: "getSelectedUserCRC"
      })
    },

    methods: {
      ...mapActions("agenda", ["addCollectionRequest"]),
      ...mapActions(["toggleSnackbar"]),
      async submitHandler(formNewRequest) {
        try {
          const response = await this.addCollectionRequest({
            ...formNewRequest,
            crc: {
              id: this.selectedCrc.id
            }
          });

          this.toggleSnackbar({
            text:
              response?.data?.message ?? "Solicitação cadastrada com sucesso",
            type: "success"
          });

          this.$router.push({ name: "agenda" });
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/forms";
  @import "@/assets/stylesheets/components";

  @include form-register();
</style>
