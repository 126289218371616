<template>
  <validation-provider :name="name" :rules="{ required: isRequired }">
    <v-radio-group
      v-model.lazy="selectedValue"
      v-bind="{ ...$attrs, ...attrs }"
      v-on="$listeners"
    >
      <template v-slot:label>
        <div></div>
      </template>
      <v-radio
        v-for="item in items"
        color="primary-color-1"
        :key="item.id"
        :label="item.description"
        :value="item.id"
      >
        <template v-slot:label>
          <div
            :style="{ width: `${itemWidth}px` }"
            class="font-weight-regular black-1--text"
          >
            {{ item.description }}
          </div>
        </template>
      </v-radio>
    </v-radio-group>
  </validation-provider>
</template>
<script>
  import FieldMixin from "@/modules/core/mixins/fieldMixin";

  export default {
    name: "RadioField",

    props: {
      items: {
        required: true
      },
      value: {
        type: null
      },
      itemWidth: {
        type: Number,
        required: false
      }
    },

    mixins: [FieldMixin],

    computed: {
      selectedValue: {
        get() {
          return this.value;
        },
        set(newVal) {
          this.$emit("input", newVal);
        }
      }
    }
  };
</script>
<style></style>
