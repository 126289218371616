<template>
  <v-dialog v-model="state" width="536">
    <v-card class="modal">
      <v-card-title class="modal-title">Selecionar Doador</v-card-title>

      <div class="px-5">
        <v-text-field
          solo
          flat
          full-width
          background-color="white-1"
          color="secondary-color-1"
          placeholder="Buscar (Ex: CPF, CNPJ ou nome)"
          prepend-inner-icon="mdi-magnify"
          @input="debounceInput"
        ></v-text-field>
      </div>
      <v-divider></v-divider>

      <validation-observer>
        <v-form @submit.prevent="submit" slot-scope="{ invalid }">
          <div v-if="donators.length" class="data-container px-5">
            <v-row class="mt-n4">
              <v-col cols="12" sm="6">
                <radio-field
                  v-model="donationType.donatorSelected"
                  type="genericText"
                  label="Doadores"
                  name="requestType"
                  :isRequired="true"
                  :items="donators"
                />
              </v-col>
            </v-row>

            <infinite-loading spinner="waveDots" @infinite="loadMore">
              <div slot="no-more"></div>
              <div slot="no-results"></div>
            </infinite-loading>
          </div>

          <div v-if="!donators.length" class="mt-5">
            <data-not-found
              :title="dataNotFound"
              :imgSrc="require('@/assets/user.svg')"
              btnLabel="Criar um novo doador"
              :canSeeAction="canRegisterDonator"
              :btnAction="goToAddDonator"
            />
          </div>

          <v-divider></v-divider>

          <v-card-actions
            v-if="donators.length"
            class="d-flex flex-wrap justify-space-between"
          >
            <v-btn
              class="mt-1 black-3--text cancel-button"
              color="#FFF"
              @click="backBtn"
              :block="$isMobile"
              >Voltar</v-btn
            >
            <v-btn
              type="submit"
              :disabled="invalid"
              color="secondary-color-1"
              class="ma-0 mt-1 white--text next-step-button"
              :block="$isMobile"
              >Selecionar doador</v-btn
            >
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
  import { debounce } from "lodash";
  import { mapActions, mapGetters } from "vuex";

  import DataNotFound from "@/modules/core/views/components/DataNotFound";
  import RadioField from "@/modules/core/views/components/RadioField";

  import { ValidationObserver } from "vee-validate";
  import InfiniteLoading from "vue-infinite-loading";

  export default {
    name: "SelectDonatorDialog",

    props: {
      value: {
        type: null
      },
      backBtn: {
        type: Function,
        required: true
      },
      state: {
        type: Boolean,
        required: true
      }
    },

    watch: {
      partnerSelected() {
        this.$emit("input", this.donatorSelected);
      }
    },

    components: {
      ValidationObserver,
      InfiniteLoading,
      DataNotFound,
      RadioField
    },

    data: () => ({
      donationType: {
        showContent: false,
        donatorSelected: "",
        donatorSelectedLabel: ""
      },
      donators: [],
      searchTerm: "",
      filterPristine: true
    }),

    async mounted() {
      await this.loadMore();
    },

    computed: {
      ...mapGetters({
        userRoleId: "user/getUserRoleId",
        routes: "getRoutes"
      }),

      dataNotFound() {
        return this.filterPristine
          ? "Busque por um doador"
          : "Doador não encontrado";
      },

      canRegisterDonator() {
        return this.routes?.some(
          route =>
            route.name === "new-donator" &&
            route?.meta?.permissions.includes(this.userRoleId)
        );
      }
    },

    methods: {
      ...mapActions("donator", ["getDonator"]),
      ...mapActions(["toggleSnackbar"]),

      async fetchDonators() {
        try {
          const payload = this.mountPayload();
          const { data } = await this.getDonator(payload);
          return this.formatData(data);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      },

      formatData(data) {
        return data.map(donator => ({
          id: donator.id,
          description: this.getDonatorName(donator)
        }));
      },

      getDonatorName(donator) {
        return `${
          donator.donatorPF
            ? donator.donatorPF.name
            : donator.donatorPJ.fantasyName
        }`;
      },

      async loadMore($state) {
        const data = await this.fetchDonators();
        if (data.length) {
          this.donators = [...data];
          if ($state) {
            $state.complete();
          }
        }
      },

      debounceInput: debounce(async function(value) {
        this.filterPristine = false;
        this.searchTerm = value;

        this.donators = await this.fetchDonators();
      }, 800),

      async submit() {
        this.donationType.donatorSelectedLabel = this.donators.find(
          el => el.id === this.donationType.donatorSelected
        ).description;
        this.donationType.dialog = false;
        this.$emit("input", this.donationType);
      },

      mountPayload() {
        return {
          search: this.searchTerm
        };
      },

      goToAddDonator() {
        this.$router.push({ name: "new-donator" });
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .data-container {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }
</style>
